import React, {useRef } from 'react';
import { ControlledMenu, useHover, useMenuState} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import PropTypes from 'prop-types';
import './reactMenu.css';


const ReactMenu = ({renderMenu = null, label ='Menu'})=>{
    // menu items
    const ref = useRef(null);
    const [menuState, toggle] = useMenuState({ transition: {open:true, close: false}, transitionTimeout: 300 });
    const { anchorProps, hoverProps } = useHover(menuState.state, toggle);

    return (
        <div className="font-display-med react-menu-container">
            <span ref={ref} {...anchorProps} className='font-display-med react-menu-name'>
                {label}
            </span>
            <ControlledMenu
                {...hoverProps}
                {...menuState}
                anchorRef={ref}
                onClose={() => toggle(false)}
                align='center'
                overflow='auto'
                boundingBoxPadding="20"
                arrow={true}
                position="anchor"
                setDownOverflow
            >
                {renderMenu}
            </ControlledMenu>
        </div>
    );
};

ReactMenu.propTypes = {
    renderMenu:PropTypes.any,
    label:PropTypes.string
};

export default ReactMenu;