import React,{useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tableau } from '@jauj/reactpkg-tsis-tableau';
import { getRequest } from '../../../services/axiosClient';
import { getBaseUrl } from '../../../util/getBaseUrl';
import { checkDefault, separateFiltersAndParameters, getErrorMsg } from '../../../util/functions';
import Loading from '../../../components/common/loading';
import { httpStatuses } from '../../../config/constants';
import Error from '../../../components/common/error';

const dashboardFilterParams = `${getBaseUrl()}/api/datasrcs/1/folder/dataviz/customQuery/get_mylink_filters_params?filters=`;
const defaultFilterParamsUrl = `${getBaseUrl()}/api/datasrcs/1/schemas/dataviz/tables/workbook_view/records?id=`;

// Iframe load time to show the loading
const iframeLoadTimeout = 1000;

// Tableau configurations
const options = {
    height: 2300,
    width: '100%',
    toolbar: 'top',
};

const DashboardTableau = ({handleTabSwitch, mylink, urlFilters, sideMenuData, dashboardViews, productId, workbookId, workbookViewId, respData})=>{
    const [filterParams, setFilterParams] = useState({ status: false, parameters: [], filters: [], mylink: null });
    
    const [err, setErr] = useState({ isErr: false, status: null, msg: ''});

    const handleErrorClose = () => setErr({ isErr: false, status: null, msg: '' });

    useEffect(() => {
        const loadFilters = async () => { 
            setFilterParams({ status: false, mylink: null });
            const { filters, parameters } = await getDefaultFilters();
            const myLinkDefault = checkDefault(dashboardViews, `${productId}/${workbookId}/${workbookViewId}`);

            // Check for additional filter and parameter values other than the defaults
            if(mylink) {
                const myLinkParams = await getMyLinkParams(mylink);
                filters.push(...myLinkParams.filters);
                parameters.push(...myLinkParams.parameters);
            } else if(urlFilters)  {
                filters.push(...getUrlFilters(urlFilters)); 
            } else if (myLinkDefault) {
                const myLinkParams = await getMyLinkParams(myLinkDefault);
                filters.push(...myLinkParams.filters);
                parameters.push(...myLinkParams.parameters);
            } else { 
                // Sonar else
            }

            // Combine filter values together
            const combinedFilters = filters.reduce( (acc, cur) => { 
                const found = acc.find(i => i.field === cur.field);
                if (found) { 
                    found.values = [...new Set([...found.values, ...cur.values])];
                } else { 
                    acc.push({ ...cur});
                }
                return acc;
            }, []);

            // For duplicate parameters, take the last value added to the array.
            // This means MyLink parameters will overwrite default parameters since they are added last.
            const combinedParamters = Object.values(
                parameters.reduce( (acc, cur) => { 
                    acc[cur.name] = cur;
                    return acc;
                }, {})
            );
            setTimeout( () => setFilterParams({ status: true, filters: combinedFilters, parameters: combinedParamters, mylink: mylink || myLinkDefault ? true : false }), iframeLoadTimeout );

        };
        loadFilters();

    }, [mylink, urlFilters]);


    const getDefaultFilters = async () => { 
        try { 
            const resp = await getRequest(defaultFilterParamsUrl + workbookViewId);
            const record = resp.data.data[0];
            return { filters: record.default_filters ?? [], parameters: record.default_parameters ?? [], status: true };

        } catch (error) {
            setErr({ 
                isErr: true,
                status: (error?.response?.status ?? httpStatuses.SERVER_ERROR),
                msg: 'Error while retrieving default filters and parameters' + getErrorMsg(err)
            });
        }
        return { filters: [], parameters: [] };
    };

    const getMyLinkParams = async myLinkId => {
        try {
            // Send an HTTP request to get the filter parameters
            const res = await getRequest(dashboardFilterParams + myLinkId);
    
            // Initialize filters and parameters
            let initialFilters = [];
            let initialParameters = [];
    
            // If there is data available, process each item
            if (res.data.rowCount > 0 && Array.isArray(res.data.data)) {
                // Assign new values to the variables
                ({ initialFilters, initialParameters } = separateFiltersAndParameters(res.data.data));
            }
    
            // Update the component state with the fetched filters and parameters
            return { filters: initialFilters, parameters: initialParameters };
        } catch (error) {
            setErr({ 
                isErr: true,
                status: (error?.response?.status ?? httpStatuses.SERVER_ERROR),
                msg: 'Error while retrieving MyLink filters and parameters' + getErrorMsg(err)
            });
        }
        return { filters: [], parameters: [] };
    };

    const getUrlFilters = filterObj => { 
        const initialFilters = [];
        Object.entries(filterObj).forEach( ([key, value]) => {
            const valueArr = value.split(',');
            initialFilters.push({
                field: key,
                values: valueArr
            });
        });
        return initialFilters;
    };

    return (
        <>
            <div className={`tableau-report ${sideMenuData.length <= 1 ? 'full-width' : ''}`}>
                { err.isErr && <Error open={true} error={err.msg} status={`${err.status}`} onErrorWasClosed={handleErrorClose}/> }
                {!filterParams.status ? <Loading isLoading={true} /> :
                    <>
                        <Tableau
                            reportUrl={respData.url + '&:jsdebug=n'}
                            options={options}
                            initialFilters={filterParams.filters}
                            initialParameters={filterParams.parameters}
                            onTabSwitched={handleTabSwitch}
                        />
                    </>
                }
            </div>
        </>
    );
};

DashboardTableau.propTypes = {
    handleTabSwitch : PropTypes.func,
    mylink: PropTypes.any,
    urlFilters: PropTypes.object,
    sideMenuData: PropTypes.array,
    dashboardViews: PropTypes.array,
    productId: PropTypes.string,
    workbookId : PropTypes.string,
    workbookViewId: PropTypes.string,
    respData: PropTypes.object
};

export default React.memo(DashboardTableau);