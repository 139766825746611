import React, { useState, useEffect } from 'react';
import { getRequest } from '../../services/axiosClient';
import { getBaseUrl } from '../../util/getBaseUrl';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import './AuditLog.css';
import AuditLogDetailsTable from './AuditLogDetailsTable';
import { ToastContainer, toast } from 'react-toastify';
import { getDetailErrorMsg } from '../../util/functions';
import { Link } from 'react-router-dom';

const AuditLogDetails = () => { 

    const [auditData, setAuditData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tableId = queryParams.get('tableid');
        const recordId = queryParams.get('recordid');
        const loadData = async () => { 
            setLoading(true);
            try { 
                const auditResponse = await getRequest(`${getBaseUrl()}/api/getauditlog/tableid/${tableId}/recordid/${recordId}`);
                setAuditData(auditResponse.data.data);
            } catch (e) {
                toast.error(`Error loading data: ${getDetailErrorMsg(e)}`);
            } finally { 
                setLoading(false);
            }
        };
        if(tableId && recordId) { 
            loadData();
        } else { 
            toast.error('Error: tableid and recordid must be provided in the URL.');
            setLoading(false);
        }
    }, []);

    if(loading) { 
        return <LoadingAnimation/>;
    }

    return(
        <div className='audit-container'>
            <div className='audit-details-header'>
                <h2 className='bold'>Audit Log Details:</h2>
                <p>{auditData[0]?.table_id_dv ?? 'N/A'}</p>
            </div>
            <Link className='btn btn-blue' to='/admin/AuditLog' style={{width:190, marginBottom:'1rem'}}>Audit Search Page</Link>
            <AuditLogDetailsTable auditData={auditData}/>
            <ToastContainer theme='light' position='bottom-right' />
        </div>
    );
};

export default AuditLogDetails;