
import React, { useEffect} from 'react';
import PropTypes from 'prop-types';
import './dashboardGroup.css';
import Error from '../../../components/common/error';
import bookImg from '../../../assets/bookOpen.png';
import useDashboardStore from '../../../store/dashboard/useDashboardStore';
import LoadingAnimation from '../../../components/common/loadingAnimation/LoadingAnimation';
import { Link } from 'react-router-dom';
import {TbExternalLink} from 'react-icons/tb';

const ProductEntry = props => {
    const {productName, workbooks, productPath} = props.product;
    return (
        <div>
            <div onClick={props.toggleProduct}className={'dashboard-product'} style={props.isExpanded ? {fontWeight:600} : {}}>
                <div>{props.isExpanded ? '-' : '+'} {productName} </div><Link to={`/dashboards/${productPath}`} className='dashboard-link-icon'><TbExternalLink fontSize={20}/></Link>
            </div>
            {props.isExpanded && (
                <ul className='dashboard-workbook-cont'> 
                    {workbooks.map(obj => (
                        <Link key={obj.workbook} to={`/dashboards/${obj.dashboard_path}`} className={'dashboard-product-workbook'}>
                            {obj.workbook}  
                        </Link>
                        
                    ))}
                </ul>
            )}
        </div>
    );
};

ProductEntry.propTypes = {
    product: PropTypes.object.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    toggleProduct: PropTypes.func.isRequired,
};
const DashboardGroup = props =>{
    // Handling http call for dashboard landing page data
    const {getDashboardLandingData, productGroups, expandedProducts, setExpandedProducts, selectedGroup, setSelectedGroup} = useDashboardStore();

    const toggleProduct = (product) => {
        setExpandedProducts({
            ...expandedProducts,
            [product]: !expandedProducts[product]
        });
    };
    
    
    useEffect(()=>{
        // Making API call to get the data
        if(!productGroups?.fetched){
            getDashboardLandingData();
        }
         
    },[]);

   
    if(productGroups?.fetched && productGroups?.status === 'OK'){
        return <div className='dashboard-main-container'><div className='dashboard-main'>
            <img src={bookImg} alt={'Background'} />
            <div className="dashboard-container">
                <div className='dashboard-group-container'>
                    <div className='dashboard-product-title font-display-med'>Product Groups</div>
                    {Object.keys(productGroups.productGroups).map(group => (
                        <div key={group} onClick={() => setSelectedGroup(group)} 
                            className={`${selectedGroup !== group ? 'dashboard-group-product' : 'dashboard-group-product d-selected'} font-display-med`}>
                            {group}
                        </div>
                    ))}
                </div>
                <div className='dashboard-product-cont'> 
                    {productGroups.productGroups[selectedGroup] && <>
                        <div className='dashboard-product-title font-display-med'>{selectedGroup} Products</div>
                        <div className='dashboard-product-wrapper'>
                            {Object.keys(productGroups.productGroups[selectedGroup]).map(products => (
                                <ProductEntry  
                                    product={productGroups.productGroups[selectedGroup][products]} 
                                    key={productGroups.productGroups[selectedGroup][products].productPath}
                                    isExpanded={expandedProducts[products] || false}
                                    toggleProduct={() => toggleProduct(products)}
                                />
                            ))}
                        </div>
                    </>}
                </div>
            </div>
            {!props.hideLink && <div  className='homepage-dashboard-cont'>
                <Link className='homepage-dashboard-link' to={{pathname: '/dashboards'}}>
                           Dashboard Page <TbExternalLink fontSize={20}/> 
                </Link>
            </div>}
        </div></div> ;
    }
    if(productGroups?.fetched && (productGroups?.status === 'ERROR')){
        return <Error open={true} error={productGroups.errorText} status = {'401'} />; 
    }
    return <LoadingAnimation />;
};
DashboardGroup.propTypes = {
    hideLink: PropTypes.bool,
};

export default DashboardGroup;
