import React, { useEffect, useState, useReducer } from 'react';
import { ProcessFormData, IsTrue, RemoveAuditDataFromColumns } from './actionHandlers';
import { ColumnRemover, BoolToString, IsDvVal, ArrayToString } from './RowEditingCommands';
import './DatagridBox.css';
import Loading from '../../../components/common/loading';
import LoadingAnimation from '../../../components/common/loadingAnimation/LoadingAnimation';
import Error from '../../../components/common/error';
import { Form } from '../../../components/common/Forms/Form';
import PropTypes from 'prop-types';
import { BiPlus, BiUpload} from 'react-icons/bi';
import UploadForm from '../../../components/uploadData/UploadForm/UploadForm';
import CustomPopup from '../../../components/common/customPopup/CustomPopup';
import Datagrid from './Datagrid';
import ExportCSV from '../../../components/common/ExportCSV';
import {datagridReducer, INITIAL_STATE} from './datagridReducer';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../services/axiosClient';
import { getErrorMsg, getDetailErrorMsg } from '../../../util/functions';
import { handleEditClickWorker, handleAddClickWorker, handleDeleteClickWorker } from './buttonClickHandlers';
import RelationshipDataHandler from './RelationshipDataHandler';
import useUserStore from '../../../store/user/useUserStore';


//  ------------------------------ data grid preprocessing -----------------------------------------


//Processes all rows after an edit to ensure DV value is used. 
//Config is needed for this operation
//If there are preformance issues, an alternative would be to run operation for edited row, then add to existing rows. 
const RowAPIProcessor = (apiRows, dispatch, state) => {
    for (let i in apiRows) {
        // eslint-disable-next-line no-prototype-builtins
        if (apiRows.hasOwnProperty(i)) {
            apiRows = _RowApiProcessor(apiRows, i, state.Config);
        }
    }
    dispatch({type:'SET_DISPLAY_VALUE_ROWS', dvRows:apiRows});
};

const formOpenForChangeByACLAccess = (formType, dispatch, props) => {
    if (formType == 'edit' || formType == 'copy') {
        if (props.ACL.update_any_records) {
            dispatch({type:'FORM_OPEN'});
        }
        else (dispatch({type:'HANDLE_ACL_POPUP_OPEN'}));
    }
    else if (formType == 'add') {
        if (props.ACL.create_record) {
            dispatch({type:'FORM_OPEN'});            
        }
        else (dispatch({type:'HANDLE_ACL_POPUP_OPEN'}));
    }else{
        //sonar else
    }
};

//initial dv row processing. Converts row to dv value facing, for use in instant data rendering.
//also used in displaying form data from url. 
const RowIntakeProcessor = (orgRows, dispatch) => {
    //formats column headers
    let processedRows = Object.assign([], orgRows);
    //convert values to dv per row
    for (let i in orgRows) {
        // eslint-disable-next-line no-prototype-builtins
        if (orgRows.hasOwnProperty(i)) {
            processedRows = ColumnRemover(orgRows, processedRows, i, '_dv');
        }
    }
    processedRows = BoolToString(processedRows);
    processedRows = ArrayToString(processedRows);
    dispatch({type: 'SET_DISPLAY_VALUE_ROWS', dvRows: processedRows});
};

//subset of apiprocessor that hanldes the conversion back to api format
const _RowApiProcessor = (apiRows, i, Config) => {
    for (let row in apiRows[i]) {
        // eslint-disable-next-line no-prototype-builtins
        if (apiRows[i].hasOwnProperty(row)) {
            let dvDict = Config[row]?.display_values;
            if (dvDict) {
                apiRows[i][row] = dvDict[apiRows[i][row]] ? dvDict[apiRows[i][row]] : apiRows[i][row];
            }
        }
    }

    return apiRows;
};


const isValidRow = (rows, uniqueId, recordId) => {

    let valid = rows.some(row => row[uniqueId] == recordId);
    //this if will allow record link of 0 to be passed through as a new record creation. Blank record.
    if (recordId == 0) {
        return true;
    }
    return valid;
};


//datagrid component
export const DatagridBox = props => {

    DatagridBox.propTypes = {
        url: PropTypes.string,
        recordId: PropTypes.string,
        tableId: PropTypes.string,
        ACL: PropTypes.any,
        action: PropTypes.any,
        query: PropTypes.any,
    };
    //define url for API requests
    const dvDisplayandAuditLog = '?audit-create=true&display-value=true&multi-select=true';
    const dvDisplay = '?display-value=true&multi-select=true';
    //config needed to set options for dropdown on select in from component
    const urlconf = `${props.url}/config?display-value=true`;
    const urlreq = `${props.url}/records`;

    //-------------------------------Object States----------------------------------
    const [state, dispatch] = useReducer(datagridReducer, INITIAL_STATE);
    //data on get request
    const {userInfo} = useUserStore();

    //control for error alert open
    const [editRowId, setEditRowId] = useState(props.recordId ? props.recordId : null);
    const [formType, setFormType] = useState(props.action ? props.action : null);
    const query = props.query ? '&' + props.query : '';

    const[isLoading, setIsLoading] = useState(false);

    //-----------------------------Access Control:----------------------------------------
    const AccessControlList = props.ACL;
    //-----------------------------API CALLS for table data:----------------------------------------

    //recives table data and configures states
    const getTableData = async () => {
        
        //uses service to fetch row data from table
        //State holds three 'row' objects:
        //Rows ~ Holds original Row data with DV information. Used to create display rows aka dvRows and convert back before api push.
        //dvRows ~ Holds transformed rows with dv replacement and object replacement.
        //rawRows ~ Holds raw non dv data for use when uploading 
       
        try{
            const allData = await getRequest(urlreq + dvDisplayandAuditLog + query);
            const newCol = allData.data.columns.filter(col => IsDvVal(col));
            const rowData = { ...allData.data.data };
            dispatch({type:'FETCH_TABLE_AllDATA_SUCCESS',
                Rows:allData.data.data, 
                Columns: newCol,
            });
            RowIntakeProcessor(rowData, dispatch);

            const res = await getRequest(urlreq);
            dispatch({type:'FETCH_TABLE_RAW_SUCCESS', rawRows:res.data.data});

 
        }catch(err){
            const msg = getErrorMsg(err);
            dispatch({type:'FETCH_ERROR', 
                requestMsg:msg, 
                requestStatus: err?.request?.status});
            setEditRowId(null);

        }
    };
 

    const getConfigData = async () => {
        //uses service to fetch config data
        setIsLoading(true);
        try{
            const res  = await getRequest(urlconf);
            dispatch({type:'FETCH_CONFIG_SUCCESS',
                Config:res.data.columnConfig,
                tableName:res.data.tableConfig.tableName,
                tableNameDV:res.data.tableConfig.tableNameDV,
                tableUniqueId:res.data.tableConfig.uniqueIdColumn[res.data.tableConfig.tableName],
            });
            setIsLoading(false);
        }catch(err){
            const msg = getErrorMsg(err);
            dispatch({type:'FETCH_ERROR', 
                requestMsg:msg, 
                requestStatus: err?.request?.status});
            setEditRowId(null);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        dispatch({type:'FETCH_START'});
        getConfigData();
        getTableData();
        formOpenForChangeByACLAccess(formType, dispatch, props);

        //cleans state when unmounting
        return () => {
            dispatch({type:'CLEAN_STATE_USEEFFECT'});
        };
    }, [props.tableId]);


    //-----------------------------API CALLs ... note: Calls will begin with 'send...'----------------------

    const sendUpdateAdd = async addData => {
        //adds user to request body
        let {updateData, multiSelectData} = ProcessFormData(addData, state.Config);
        try{
            const res  = await postRequest(urlreq, updateData);
            //use the ID from newly created object to update multiselects
            const newId = editRowId ? editRowId : res.data.data[0][state.tableUniqueId];
            RelationshipDataHandler(multiSelectData, newId, 'add');
            dispatch({type: 'FETCH_UPDATE_STATUS', requestStatus: res.request.status});
            dispatch({type: 'FETCH_UPDATE_ADD_SUCCESS'});
            getTableData();
            setFormType('');
        }catch(err){
            const msg = getDetailErrorMsg(err);
            dispatch({type:'FETCH_ERROR', 
                requestMsg:msg, 
                requestStatus: err?.request?.status});
        }  
        //returning for loading icon in form
        return false;
        
    };
    const sendUpdateForRecordUsingId = async (recordId, data) => {
        // adds user to request body
        //seperate api call that pushes new data.
        let {updateData, multiSelectData}= ProcessFormData(data, state.Config); //split data by multiselect and regular table
        try{
            const res  = await putRequest(`${urlreq}/${recordId}`, updateData);
            RelationshipDataHandler(multiSelectData, recordId,  'edit');
            dispatch({type: 'FETCH_UPDATE_STATUS', requestStatus: res.request.status});
            dispatch({type: 'FETCH_UPDATE_EDIT_SUCCESS'});
            getTableData();
            setEditRowId(null);
            setFormType('');
        }catch(err){
            const msg = getDetailErrorMsg(err);
            dispatch({type:'FETCH_ERROR', 
                requestMsg:msg, 
                requestStatus: err?.request?.status});
        } 
        //returning for loading icon in form
        return false;
    };
    //-------------------------- Action Handlers -----------------------------------------

    const handleFormSubmit = data => {
        if(formType == 'edit'){
            return sendUpdateForRecordUsingId(editRowId, data);
            //add or edit launch same command
        }else{
            return sendUpdateAdd(data);
        }
    };

    // send update to backend for deletion of record
    const sendUpdateDelete = async (recordId, newRows) => {
        try{
            //RelationshipDataHandler(addDataFinal, state.Config, 'delete');
            await deleteRequest(`${urlreq}/${recordId}`);
            dispatch({type: 'FETCH_UPDATE_DELETE_SUCCESS', Rows: newRows});
            setEditRowId(null);
            RowAPIProcessor(newRows, dispatch, state);
        }catch(err){
            const msg = getErrorMsg(err);
            dispatch({type:'FETCH_DELETE_ERROR', 
                requestMsg:msg, 
                requestStatus: err?.request?.status
            });
        }
    };
    
    //---------------------------- Button Click Handlers ----------------------------------

    const handleCancelClick = () => {
        dispatch({type:'HANDLE_CANCEL_CLICK'});
        setEditRowId(null);
        setFormType('');
    };

    const handleUploadCancelClick = () => {
        dispatch({type:'HANDLE_UPLOAD_CANCEL_CLICK'});
    };

    const handleACLWasClosed = () => {
        dispatch({type:'HANDLE_ACL_WAS_CLOSED'});
    };

    const handleErrorWasClosed = () => {
        dispatch({type:'HANDLE_ERROR_WAS_CLOSED'});
    };

    const handleEditClick = (event, row, actionType = null) => {
        let {rowId} = handleEditClickWorker(event, row, AccessControlList, state, dispatch, userInfo);
        setEditRowId(rowId); 
        setFormType(actionType);
    };

    const handleAddClick = () => {
        handleAddClickWorker(AccessControlList, setFormType, setEditRowId, dispatch);
    };
    //this func execute if the user confirms deletion via popup alert
    const handleDeleteClick = () => {
        handleDeleteClickWorker(state, sendUpdateDelete, dispatch);
    };
    //this func is executed when user attempts to delete, and is given warning or acl alert
    const handleDeleteButtonClick = (row, i) => {
        const { userName } = userInfo;
        const {delete_any_records, delete_own_records} = AccessControlList;
        if (delete_any_records === 1 || (delete_own_records === 1 && userName === row.audit__create_user)) {
            dispatch({type: 'DELETE_POPUP', openDeletePopup: true, targetDelete:parseInt(i)});
        } else { dispatch({type: 'HANDLE_ACL_POPUP_OPEN'}); }
    };

    const deleteBTNCLose = () => {
        dispatch({type:'DELETE_POPUP', openDeletePopup:false, targetDelete:null});
    };

    // ----------------------------- Logistic checks and buttons ----------------------------------
    //cycles through all tables to check valid tables
    const addItemBtn = state.tableUniqueId ? <button className='btn btn-green' role='infoadditem'
        onClick={() => {
            handleAddClick();
        }}
    >
        <BiPlus size="20px" /> Add an item
    </button> : <span className='btn-outlined btn'> <LoadingAnimation width={'20px'} height={'20px'} type={true} /></span>;

    const uploadBtn = state.tableUniqueId ? <button className='btn btn-grey'
        onClick={() => {
            dispatch({type:'HANDLE_UPLOAD', 
                uploadForm:IsTrue(AccessControlList.file_upload),
                ACLPopup:!IsTrue(AccessControlList.file_upload)
            });        
        }}
        disabled={false}
    >
        <BiUpload size="20px" /> Upload
    </button> : <span className='btn-outlined btn'> <LoadingAnimation width={'20px'} height={'20px'} type={true} /></span>;

    //BUILD OUT PAGE
    return !isLoading ? (
        <div className="grid-container">
            <div>
                {state.ACLPopup && <Error
                    error={'You do not have access to this command, please contact your administrator for assistance.'}
                    open={state.ACLPopup}
                    onErrorWasClosed={handleACLWasClosed}
                    status={406}
                ></Error>}
                {state.isDeleteError && <Error
                    error={state.requestMsg}
                    open={state.isDeleteError}
                    onErrorWasClosed={handleErrorWasClosed}
                    status={state.requestStatus}
                ></Error>}
            </div>
            <h1 className={'table-name'}>
                {state.tableNameDV}
            </h1>
            <div className='table-btn-container'>
                {addItemBtn}
                {uploadBtn}
                <ExportCSV
                    tableName={state.tableName}
                    url={props.url}
                    dispatch={dispatch}
                />
            </div>
            {state.formOpen && !state.configLoading && !state.isLoading && isValidRow(state.Rows, state.tableUniqueId, editRowId) && 
            <CustomPopup
                tableName={state.tableNameDV}
                recordId={editRowId}
                formType={formType}
                onClose={handleCancelClick}
                minWidth={'550px'}
                openPopup={true}
            >
                <>
                    {state.isRequestError && <Error
                        error={state.requestMsg}
                        open={state.isRequestError}
                        onErrorWasClosed={handleErrorWasClosed}
                        status={state.requestStatus}
                    ></Error>}
                    <Form
                        Config={state.Config}
                        Columns={RemoveAuditDataFromColumns(state.Columns)}
                        url={urlreq + dvDisplay}
                        rowId={editRowId}
                        uniqueId={state.tableUniqueId}
                        formType={formType}
                        handleSubmitter={handleFormSubmit}
                        handleCancelClick={handleCancelClick}
                    />
                </>
            </CustomPopup>}
            { (!state.configLoading && !state.isLoading ) ? <Datagrid 
                state={state} 
                handleEditClick={handleEditClick} 
                handleDeleteButtonClick={handleDeleteButtonClick}
            /> :<LoadingAnimation/>}
            {state.uploadForm && (
                <CustomPopup
                    title={'Upload CSV'}
                    onClose={handleUploadCancelClick}

                >
                    <UploadForm
                        cancelUploadForm={handleUploadCancelClick}
                        Columns={RemoveAuditDataFromColumns(state.Columns)}
                        Config={state.Config}
                        urlreq={props.url}
                        getTableData={getTableData}
                        rows={state.rawRows}
                        tableUniqueId={state.tableUniqueId}
                    />

                </CustomPopup>
            )}
            {state.openDeletePopup && (
                <CustomPopup formType='other' tableName={'Are you sure you want to delete?'} onClose={deleteBTNCLose}>
                    
                    <div className='data-link-popup'>
                        <div className='data-link-btns'>
                            <button data-testid='cancel-btn' className='btn btn-trans' onClick={deleteBTNCLose} style={{color:'blue'}}>Cancel</button>
                            <button disabled={isLoading} data-testid='delete-btn' className='btn btn-red' onClick={handleDeleteClick}>Delete</button>
                        </div>
                    </div>
                   
                </CustomPopup>)}
        </div>
    ) : (
        <Loading isLoading={true} />
    );
};
