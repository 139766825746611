import { inverse } from './actionHandlers';

//converts rows to have dv values as primary value
const RowDvToId = (copiedRow, Config) => {
    const myRow = { ...copiedRow };
    for (let column in myRow) {
        // eslint-disable-next-line no-prototype-builtins
        if (myRow.hasOwnProperty(column)) {
            let dvDict = Config[column]?.display_values;
            if (dvDict) {
                let i_dvDict = inverse(dvDict);
                myRow[column] = parseInt(i_dvDict[myRow[column]]);
            }
        }
    }
    return myRow;
};
const RemoveAuditDataFromRow = row => {
    let noAuditRows = { ...row };
    for (let i in noAuditRows) {
        if (i.startsWith('audit__')) {
            delete noAuditRows[i];
        }
    }
    return noAuditRows;
};

// removes dv values and deletes pk in order to have rows with proper column name
// to be used in display of datagrid
const ColumnRemover = (orgRows, processedRows, i, columnEndName) => {

    for (let key in orgRows[i]) {
        // console.log(i, key, orgRows[i][key])
        //code to filter objects into grid as comma separated values
        if(Array.isArray(orgRows[i][key]) && processedRows[i][key].length > 0){
            let rel_object = processedRows[i][key];
            // Extract display_value from each object using map()
            const displayValues = rel_object.map(item => item.display_value).filter(item => item !== undefined);

            if (displayValues.length > 0) { 
                // Join display_values using comma as the separator
                const csvString = displayValues.join(', ');
                processedRows[i][key] = csvString;

            } 
        }

        // eslint-disable-next-line no-prototype-builtins
        if (orgRows[i].hasOwnProperty(key) && key.endsWith(columnEndName)) {
            const dv_str_depth = -3;
            let oriKey = key.slice(0, dv_str_depth);
            //deletes original key and its values from data
            processedRows[i][oriKey] = processedRows[i][key];
            delete processedRows[i][key];
        }
    }
    return processedRows;
};

const SingleKeyReplacement = row => {
    let updatedRow = Object.assign({}, row);
    for (let key in row) {
        // eslint-disable-next-line no-prototype-builtins
        if (row.hasOwnProperty(key) && key.endsWith('_dv')) {
            const dv_str_depth = -3;
            let oriKey = key.slice(0, dv_str_depth);
            //deletes original key and its values from data
            updatedRow[oriKey] = [updatedRow[oriKey], updatedRow[key]];
            delete updatedRow[key];
        }
    }
    return updatedRow;
};

const BoolToString = rows => {
    rows.forEach(row => {
        for (const [key, value] of Object.entries(row)) {
            if (value === true) {
                row[key] = 'true';
            }
        }
    });
    return rows;
};

const ArrayToString = rows => { 
    rows.forEach(row => {
        for (const [key, value] of Object.entries(row)) {
            if (Array.isArray(value)) {
                row[key] = JSON.stringify(value);
            }
        }
    });
    return rows;
};

const ReturnEmptyRow = row => {
    let emptyRow = { ...row };
    for (let key in row) {
        emptyRow[key] = null;
    }
    return emptyRow;
};

//checks if value contains '_dv'
const IsDvVal = col => {
    if (!col.includes('_dv')) {
        return col;
    } else {
        return null;
    }
};

export { RowDvToId, RemoveAuditDataFromRow, ColumnRemover, BoolToString, ArrayToString, IsDvVal, SingleKeyReplacement, ReturnEmptyRow };

