import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './dataExploration.css';
import {getBaseUrl} from '../../util/getBaseUrl';
import { backendURL } from '../../config/constants';
import MyLinkDataForm from './MyLinkDataForm';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import CustomPopup from '../../components/common/customPopup/CustomPopup';
import { FaEdit, FaSave,FaDownload,FaCopy , FaTrashAlt } from 'react-icons/fa';
import PreviewIcon from '@mui/icons-material/Preview';
import { getRequest, putRequest } from '../../services/axiosClient';
import InfoMessage from '../../components/common/InfoMessage';
import { getErrorMsg } from '../../util/functions';
import axios from 'axios';
import {DataTable} from '@jauj/reactpkg-tsis-datatable';
import { Link } from 'react-router-dom';

const urlDataTableConfig = `${getBaseUrl()}${backendURL().dataExploration}`;
const urlUserViews = `${getBaseUrl()}${backendURL().dataExpSaved}`;
const urlDeleteUserView = getBaseUrl() + '/api/datasrcs/1/schemas/dataviz/tables/mylink_user/records/';
const urlGenerateData = getBaseUrl() + '/api/generateMyData/';
const urlDownloadData = getBaseUrl() + '/api/download/';


const getDownload = async (url1, filename) => {

    try {
        const response  = await axios({url: url1, method: 'GET',responseType: 'blob', withCredentials: true,});
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.xlsx`); 
        document.body.appendChild(link);
        link.click();
        return {isError:false , message:'Download has begun', status: true};
    }catch(error){
        let statusText = 'Error Occured, downloaded file may not exist. Try to Generate first. If further issues occur contact admin.';
        return {message:statusText, status:true, isError:true};
    }
};

const DataExploration = props => {
    /* Destructuring the props object. */
    const {setTitle, userId, userName} = props;
    const [respData, setRespData] = useState({fetched: false, data: []});
    const [isEditClicked, setIsEditClicked] = useState({status:false, formInfo:{action:null, myLinkId:null, formName:''}});
    const [clickedRowObj, setClickedRowObj] = useState([]);
    const [selectedTableId, setSelectedTableId] = useState(null);
    const [savedData, setSavedData] = useState({fetched: false, data: []});
    const [apiStatus, setApiStatus] = useState({status:false, message:'', error:false});
    const [openPopup, setOpenPopup] = useState({popupStatus:false, myLinkId:null});

    /**
     * Fetches data exploration tables
     * and formats the response data
     * Sets the state of fetched data to 
     * be displayed on the UI
     * If there is an error, sets the state of 
     * fetched data to an empty array and logs the error
     */
    const getExplorationData = async () =>  {
        try{
            const response = await getRequest(urlDataTableConfig);
            const tableData = response.data.data.map(({table_name_display, schema_id_dv, table_name, id}) => ({
                display_name: table_name_display ? `${table_name_display} (${schema_id_dv}.${table_name})` : `${schema_id_dv}.${table_name}`,
                data_view: '',
                data_edit: '',
                id
            }));
            setRespData({fetched: true, data: tableData});
        }catch(error){
            setRespData({fetched: true, data: []});
            const msg = getErrorMsg(error);
            setApiStatus({status:true, message:'Error with getting exploration data, please refresh page or try again later' + msg, isError:true});
        }
        
    };
    /**
     * Fetches saved user views and 
     * formats the response data
     * Sets the state of fetched saved 
     * data to be displayed on the UI
     * If there is an error, sets the state of 
     * fetched saved data to an 
     * empty array and logs the error
     */
    const getUserViews = async()=>{
        
        try {
            const res  = await getRequest(urlUserViews + userId);
            const newData = res.data.data.map(obj=>({...obj, displayName: `${obj.mydata_view_name} (${(obj.table_name_display)})` }));            
            setSavedData({fetched:true, data: newData});
        } catch (error) {
            setSavedData({fetched:true, data:[]});
            const msg = getErrorMsg(error);
            setApiStatus({status:true, message:'Error with getting saved user views, please refresh page or try again later' + msg, isError:true});
        }
    };


    /* Setting the title of the page. */
    // This effect sets the title, and fetches 
    // data for the available data marts 
    // table and user's saved views on mount
    useEffect(() => {
        if(apiStatus.status)setApiStatus({status:false, message:'', isError:false});
        setTitle('Data Exploration');
        getExplorationData();
        getUserViews();
    }, []);

    // This function sets state for the selected 
    // row object and selected table ID when 
    // the Edit button is clicked
    const handleEditClick = (record, info) => {
        setIsEditClicked({status:true, formInfo:info});
        setClickedRowObj(record);
        setSelectedTableId(record.id);
    };

    const handleDownloadClick = async info => {
        setApiStatus({status:false, message:'', isError:false});
       
        //using true for download here
        const result =  await getDownload(urlDownloadData + `${userName}-${userId}/${info.myLinkId}/host`, `mydata_${info.myLinkId}`);
     
        if(result.isError){
            setApiStatus({status:true, message:result.message, isError:true});
        }else{
            setApiStatus(result);
        }
    };

    const handleSaveClick = async info => {    
        setApiStatus({status:false, message:'', isError:false});
        
        try {
            await getRequest(urlGenerateData + `${info.myLinkId}/${userId}/${info.formName}`);
            setApiStatus({status:true, message:'Your data generation has begun, expect an email soon!', isError:false});
        } catch (error) {
            const msg = getErrorMsg(error);
            setApiStatus({status:true, message:'Error with Generating data, please refresh page or try again later' + msg, isError:true});
        }
    };

    // This function sets state for isEditClicked 
    // when the popup is closed
    const handleClosePopup = () => {
        setIsEditClicked({status:false, formInfo:{}});
    };

    // delete function
    const deleteMySavedViewData = async (myLinkId, name) => {

        //setting state
        setApiStatus({status:false, message:'', isError:false});
        try {
            // removing active flag 
            await putRequest(urlDeleteUserView + myLinkId, { active_flag: false });
            setApiStatus({status:true, message:`Deleted ${name} successfully!`, isError:false});
            setOpenPopup({popupStatus:false, myLinkId:null});
            getUserViews();
        } catch (error) {
            //setting error state
            const msg = getErrorMsg(error);
            setApiStatus({status:true, message:'Error with deleting saved user views, please refresh page or try again later' + msg, isError:true});
            setOpenPopup({popupStatus:false, myLinkId:null});
        }
    };
    const two = 2;
    // These are the columns for the 
    // available data marts table
    const columns = [
        {
            accessorKey:'display_name', 
            header: 'Available Data Marts',
            minWidth: 130,
            textAlign:'left'
        },{
            header: 'Explore',
            minWidth: 60,
            cell: (info) => (
                <Link to={info.row.original.id.toString()}>
                    <span className='btn-icon' data-testid='form-config-view'>
                        < PreviewIcon />
                    </span>
                </Link>
            ),
        },{
            header: 'Create',
            hideFilter:true,
            minWidth: 60,
            cell:info => (
                <span onClick={()=>handleEditClick({id:info.row.original.id, 
                    display_name:info.row.original.display_name, type:'myData'}, {action:'new', myLinkId:null, formName:'', generateBtn:true})} className='btn-icon'  data-testid='form-edit-data'>< FaEdit size='18px' /></span>
            ),
        }];
    const columnsSaved = [
        {
            accessorKey:'displayName', 
            header: 'Saved My Data Views',
            minWidth: 130,
            textAlign:'left'
        },
        {
            accessorKey:'type_id', 
            header: 'Type',
            minWidth: 130,
            textAlign:'left',
            disableFilter:true,
            cell:info => (
                <span>
                    {info.row.original.type_id === 1 ? 'My Link' : 'My Data'}
                </span>
            )
        },{
            header: 'Actions',
            minWidth: 160, 
            cell:info => (
                <span className='myLinkDataForm-btn-icons'>
                    
                    <span className='btn-icon' data-testid='fa-save' title='Generate Data'
                        onClick={()=> handleSaveClick(
                            {
                                action:'edit', myLinkId:info.row.original.mylink_id, 
                                formName:info.row.original.mydata_view_name
                            })}
                    >< FaSave /></span>
                    <span className='btn-icon' data-testid='fa-download' title='Download'
                        onClick={()=> handleDownloadClick(
                            {
                                action:'edit', myLinkId:info.row.original.mylink_id, 
                                formName:info.row.original.mydata_view_name
                            })}
                    ><FaDownload /></span>
                    <span onClick={()=> handleEditClick(
                        {
                            id:info.row.original.table_id, 
                            display_name:info.row.original.table_name_display,
                            type:info.row.original.type_id === 1 ? 'myLinkData' : 'myData'
                        }, 
                        {
                            action:'edit', myLinkId:info.row.original.mylink_id, 
                            formName:info.row.original.mydata_view_name,
                            generateBtn:true
                        })}  
                    className='btn-icon' 
                    data-testid='fa-edit'
                    title='Edit saved view'
                    >< FaEdit/></span>
                    {info.row.original.type_id === two ? <>
                        <span onClick={()=> handleEditClick({
                            id:info.row.original.table_id, 
                            display_name:info.row.original.table_name_display,  
                            type:info.row.original.type_id === 1 ? 'myLinkData' : 'myData'}, 
                        {action:'copy', myLinkId:info.row.original.mylink_id, formName:'', generateBtn:true})} 
                        className='btn-icon' data-testid='fa-copy' title='Copy saved view'>< FaCopy />
                        </span>
                        <span onClick={()=> setOpenPopup({myLinkId:info.row.original.my_link_user_id, popupStatus:true, name:info.row.original.displayName})}  className='btn-icon' data-testid='fa-trash' title='Delete saved view'>< FaTrashAlt /></span> </> 
                        :  
                        <>
                            <span className='btn-disabled' data-testid='fa-copy' title='Copy saved view'>< FaCopy /></span>
                            <span className='btn-disabled' data-testid='fa-trash' title='Delete saved view'>< FaTrashAlt /></span>
                        </>
                        
                    }
                </span> 
            ),
        }];
    return (
        <div className='data-exp-container'>
            {/* <h2 className='font-display-bold'>bold</h2>
            <h2 className='font-display-med'>medium</h2>
            <h2 className='font-display-reg'>regular</h2>
            <h2 className='font-display-med'>light</h2>

            <br/> */}
            <h2 className='font-display-med'>Welcome to Data Exploration!</h2>
            <section>
                <p className='mb-1 mt-1'>The Data Exploration tool provides you with the ability to explore metadata and download data from various data marts in the IT Insights Data Lake. These data marts are generated to support Dashboards within the TSPM site.</p>
                <p className='mb-1'>To explore a data mart, click on the explore icon for the data mart in the Available Data Marts table.</p>
                <p className='mb-1'>To create a My Data View and download data from a data mart, search for the data mart in the Available Data Marts table and click the Create icon. Follow the instructions to create a My Data View.</p>
                <p className='mb-1'>Once a My Data View is created, it will appear in your Saved My Data Views table. Click the icon to generate the file extract, this can be done at any time to refresh the data. Once generated, an email will be sent with a link to the file. The Saved My Data Views record will also update to include a download icon when the page is refreshed.</p>
            </section>
            <section>
                <InfoMessage message={apiStatus.message} isError={apiStatus.isError} onClose={() => setApiStatus({status:false, message:'', isError:false})} status={apiStatus.status} timer={false} showClose={true}/>
            </section>
            <section className='data-exp-tables'>
                {openPopup.popupStatus && 
                <CustomPopup tableName={'Are you sure you want to delete?'} >
                    
                    <div className='data-exp-popup'>
                        <h2>Are you sure you want to delete?</h2>
                        <div>
                            <button data-testid='cancel-btn' className='btn btn-trans' onClick={()=> setOpenPopup({popupStatus:false, myLinkId:null, name:''})} style={{color:'blue'}}>Cancel</button>
                            <button data-testid='delete-btn' className='btn btn-red' onClick={()=> deleteMySavedViewData(openPopup.myLinkId, openPopup.name)}>Delete</button>
                        </div>
                        
                    </div>
                
                </CustomPopup>}
                <div className='data-exp-table'>
                    {respData.fetched ? <DataTable
                        {...{
                            tableData:respData.data,
                            columnConfig:columns, 
                            disableDownload:true, 
                            defaultFileName:'table', 
                            showFooter:false, 
                        }}
                    /> : <LoadingAnimation/>}

                </div> 
                <div className='data-exp-table'>
                    {savedData.fetched ? <DataTable
                        {...{
                            tableData:savedData.data,
                            columnConfig:columnsSaved , 
                            disableDownload:true,
                            defaultFileName:'table', 
                            showFooter:false,
                        }}
                    /> : <LoadingAnimation/>}
                </div>
            </section>
            {isEditClicked.status &&
                <CustomPopup 
                    formType='other' 
                    tableName={`My Data Form: ${clickedRowObj.display_name}`} 
                    onClose={handleClosePopup} minWidth='80vw'
                    backColor={'#fafafa'}
                >
                    <MyLinkDataForm 
                        selectedTableId={selectedTableId} 
                        userId={userId} 
                        getUserViews={getUserViews} 
                        formInfo={isEditClicked.formInfo} 
                        setIsEditClicked={setIsEditClicked} 
                        formType={clickedRowObj.type}
                        workbookViewId={null}
                    />
                </CustomPopup>
            }
        </div>
    );
};

DataExploration.propTypes={
    setTitle:PropTypes.any,
    userId:PropTypes.string,
    userName:PropTypes.string,
};

export default DataExploration;